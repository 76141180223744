






.facture-montant-container {
    position: relative;
    background-color: #dedede;
    display: inline;
    width: 500px;
    
}
    

.facture-montant-container:after {
    content: '€';
    position: absolute;
    top: 0;
    right:0px;
    z-index: 1;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


