.LayoutA {
    // .container {
    //     width: 1390px;
    //     margin: auto auto;
    //     position: relative;
    // }
}
.LayoutC {
    .container {
        width: 500px;
        margin: auto auto;
    }

    .headList li{
        display: inline-block;
        margin: 10px;
        border: 3px solid #eee;
        width: 50px;
        height: 5px;
        border-radius: 10px;

        &#selected {
          background-color: orange!important;
          border: 2px solid orange;
        }
    }
}