.slick-slider {
    margin: 30px auto 50px;
    width: auto;
}


.slider-card {
  position: relative;
  border-radius: 20px;
  margin: 0 3px;
  border: 1px solid #ede9e6;
  // box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
//   border-top: 6px solid #D8D8D8;
  transition: all 0.3s ease-in-out;
  background-color: #fff;
  z-index: 1;
  &:hover {
    // border-top: 6px solid #0080AD;
    cursor: pointer;
    transform: scale(1.02);
  }

  .info {
      float:right;
      width: 23px;
  }

  h1 {
      font-size: 2.5em;
  }
}

.slick-prev {
  background: black;
}

.slick-next {
  background: black;
}
.slick-dots li {
  display: inline-block;
  margin: 0 16px;
}
.slick-dots li button:before {
  content: " ";
  background: #D8D8D8;
  opacity: 1;
  height: 4px;
  width: 40px;
}
.slick-dots li.slick-active button:before {
  content: " ";
  background: #0080AD;
  opacity: 1;
  height: 4px;
  width: 40px;
}