@each $value in $marginValues {
  .m-#{$value} {
    margin: #{$value}px;
  }

  .ml-#{$value} {
    margin-left: #{$value}px;
  }

  .mr-#{$value} {
    margin-right: #{$value}px;
  }

  .mt-#{$value} {
    margin-top: #{$value}px;
  }

  .mb-#{$value} {
    margin-bottom: #{$value}px;
  }

  .mv-#{$value} {
    margin-top: #{$value}px;
    margin-bottom: #{$value}px;
  }

  .mh-#{$value} {
    margin-left: #{$value}px;
    margin-right: #{$value}px;
  }

  .m-auto {
    margin: auto;
  }
}

@each $value in $marginValues {
  .p-#{$value} {
    padding: #{$value}px;
  }

  .pl-#{$value} {
    padding-left: #{$value}px;
  }

  .pr-#{$value} {
    padding-right: #{$value}px;
  }

  .pt-#{$value} {
    padding-top: #{$value}px;
  }

  .pb-#{$value} {
    padding-bottom: #{$value}px;
  }

  .pv-#{$value} {
    padding-top: #{$value}px;
    padding-bottom: #{$value}px;
  }

  .ph-#{$value} {
    padding-left: #{$value}px;
    padding-right: #{$value}px;
  }
}


.d-flex {
  display: flex;
}


@mixin clearfix() {

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}


@each $label, $map in $breakpoint-map {

  @if 'mobile' !=$label {

    @media screen and (min-width: map-get($map, min-width)) {

      .row {
        @include clearfix();

        .col {
          float: left;

          &.is-half,
          &.is-half-#{$label} {
            width: percentage(1 / 2);
          }

          &.is-one-third,
          &.is-one-third-#{$label} {
            width: percentage(1 / 3);
          }

          &.is-two-third,
          &.is-two-third-#{$label} {
            width: percentage(2 / 3);
          }

          &.is-one-quarter,
          &.is-one-quarter-#{$label} {
            width: percentage(1 / 4);
          }

          &.is-two-quarter,
          &.is-two-quarter-#{$label} {
            width: percentage(2 / 4);
          }

          &.is-three-quarter,
          &.is-three-quarter-#{$label} {
            width: percentage(3 / 4);
          }

          &.is-one-fifth,
          &.is-one-fifth-#{$label} {
            width: percentage(1 / 5);
          }

          &.is-two-fifth,
          &.is-two-fifth-#{$label} {
            width: percentage(2 / 5);
          }

          &.is-three-fifth,
          &.is-three-fifth-#{$label} {
            width: percentage(3 / 5);
          }

          &.is-four-fifth,
          &.is-four-fifth-#{$label} {
            width: percentage(4 / 5);
          }

          &.is-one-sixth,
          &.is-one-sixth-#{$label} {
            width: percentage(1 / 6);
          }

          &.is-five-sixth,
          &.is-five-sixth-#{$label} {
            width: percentage(5 / 6);
          }
        }

        &.gutter-s {

          >.col {
            padding-right: 5px;
            padding-left: 0;

            &:last-child,
            &.is-last-col {
              padding-right: 0;
            }
          }
        }

        &.gutter-m {

          >.col {
            padding-right: 10px;
            padding-left: 0;

            &:last-child,
            &.is-last-col {
              padding-right: 0;
            }
          }
        }

        &.gutter-l {

          >.col {
            padding-right: 40px;
            padding-left: 0px;

            &:first-child,
            &.is-first-col {
              padding-left: 0;
            }

            &:last-child,
            &.is-last-col {
              padding-right: 0;
            }
          }

          &.gutter-styled {

            >.col {

              &:last-child,
              &.is-last-col {
                border-left: 2px solid white;
                padding-left: 30px;
              }
            }
          }
        }

        &.is-table {
          display: table;

          >* {
            display: table-cell;
            vertical-align: middle;

            &.col {
              float: none !important;
            }
          }
        }
      }
    }
  }
}


@each $label, $map in $breakpoint-map {

  @if not map-get($map, min-width) {
    @media screen and (max-width: map-get($map, max-width)) {
      .is-hidden-#{$label} {
        display: none !important;
      }
    }
  }

  @else if not map-get($map, max-width) {
    @media screen and (min-width: map-get($map, min-width)) {
      .is-hidden-#{$label} {
        display: none !important;
      }
    }
  }

  @else {
    @media screen and (min-width: map-get($map, min-width)) and (max-width: map-get($map, max-width)) {
      .is-hidden-#{$label} {
        display: none !important;
      }
    }
  }
}