.wrapper-autocomplete {
  max-width: 100%;


  #autocomplete {
    margin: 0;
    padding: 0;
  }
  input {
    border: 0;
    width: 100%;
    max-width: 100% !important;
    height: 30px;
    margin: 5px 0;
    font-size: 0.9em;
    font-weight: bold;
    @media screen and (max-width: $mobile-max) {
      width: 100%;
      height: auto;
      font-size: 0.7em;
    }
  }

  input::placeholder {
    color: #ccc;
  }

  input:focus {
    outline: none;
  }
}

.addressBar {
  position: absolute;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  width: 715px;
  max-width: 95%;
  padding: 8px;
  background-color: #fff;
  z-index: 999;


  @media screen and (max-width: $mobile-max) {
    width: 95%;
    max-width: auto;
    padding: 4px;
  }


  .wrapper-left {
    display: flex;

    @media screen and (max-width: $mobile-max) {
        display: block;
      }
  }

  @media screen and (max-width: $mobile-max) {
    .icon {
      display: none;
    }
  }
  .icon {
    width: 20px;
    margin: 20px 20px 0 20px;
  }

  &.open {
    border-radius: 31px 31px 0 0;
  }

  &.close {
    border-radius: 50px;
  }
}

.pac-container {
  position: absolute;
  margin-left: -30px;
  margin-top: 20px;
  border-radius: 0 0 20px 20px;
  //padding: 10px;
  color: #333;

  @media screen and (max-width: $mobile-max) {
    width: 95vw!important;
    margin-top: 10px;
    margin-left: -20px;
  }

}

.home-autocomplete {
  label {
    font-size: 0.9375em;
  }
  width: 385px;
  @media screen and (max-width: $mobile-max) {
    padding: 0 15px;
    width: 100%;
  }
}

.map-autocomplete {
  width: 500px;
  @media screen and (max-width: $mobile-max) {
    width: 100%;
  }
}
