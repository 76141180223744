@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap");

@font-face {
  font-family: "fieldwork-geo";
  src: url("https://f.hubspotusercontent00.net/hubfs/7525458/Hellio%20Corporate%20Site%202020/Font/fieldwork-geo-hair-webfont.woff2")
      format("woff2"),
    url("https://f.hubspotusercontent00.net/hubfs/7525458/Hellio%20Corporate%20Site%202020/Font/fieldwork-geo-hair-webfont.woff")
      format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "fieldwork-geo";
  src: url("https://f.hubspotusercontent00.net/hubfs/7525458/Hellio%20Corporate%20Site%202020/Font/fieldwork-geothin-webfont.woff2")
      format("woff2"),
    url("https://f.hubspotusercontent00.net/hubfs/7525458/Hellio%20Corporate%20Site%202020/Font/fieldwork-geothin-webfont.woff")
      format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "fieldwork-geo";
  src: url("https://pages.hellio.com/hubfs/Hellio%20Corporate%20Site%202020/Font/fieldwork-geo-light-webfont.woff2")
      format("woff2"),
    url("https://pages.hellio.com/hubfs/Hellio%20Corporate%20Site%202020/Font/fieldwork-geo-light-webfont.woff")
      format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "fieldwork-geo";
  src: url("https://f.hubspotusercontent00.net/hubfs/7525458/Hellio%20Corporate%20Site%202020/Font/fieldwork-geo-regular-webfont.woff2")
      format("woff2"),
    url("https://f.hubspotusercontent00.net/hubfs/7525458/Hellio%20Corporate%20Site%202020/Font/fieldwork-geo-regular-webfont.woff")
      format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "fieldwork-geo";
  src: url("https://f.hubspotusercontent00.net/hubfs/7525458/Hellio%20Corporate%20Site%202020/Font/fieldwork-geo-demibold-webfont.woff2")
      format("woff2"),
    url("https://f.hubspotusercontent00.net/hubfs/7525458/Hellio%20Corporate%20Site%202020/Font/fieldwork-geo-demibold-webfont.woff")
      format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "fieldwork-geo";
  src: url("https://f.hubspotusercontent00.net/hubfs/7525458/Hellio%20Corporate%20Site%202020/Font/fieldwork-geo-bold-webfont.woff2")
      format("woff2"),
    url("https://f.hubspotusercontent00.net/hubfs/7525458/Hellio%20Corporate%20Site%202020/Font/fieldwork-geo-bold-webfont.woff")
      format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "fieldwork-geo";
  src: url("https://f.hubspotusercontent00.net/hubfs/7525458/Hellio%20Corporate%20Site%202020/Font/fieldwork-geo-bold-webfont.woff2")
      format("woff2"),
    url("https://f.hubspotusercontent00.net/hubfs/7525458/Hellio%20Corporate%20Site%202020/Font/fieldwork-geo-bold-webfont.woff")
      format("woff");
  font-weight: 700;
  font-style: normal;
}

.is-light {
  font-weight: 200;
}

.is-normal {
  font-weight: 400;
}

.is-semibold {
  font-weight: 500;
}

b,
strong,
.is-bold {
  font-weight: 600;
}

.is-extrabold {
  font-weight: 700;
}

.is-italic {
  font-style: italic;
}

a {
  &.is-underlined {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

// ==============
//  Text Colors
// ==============

.text-main {
  color: $maincolor;
}

.text-sub {
  color: $subcolor;
}

.text-gray {
  color: #666;
}

.text-white {
  color: white;
}

.text-blue {
  color: $blue;
}

// ==============
//  H
// ==============

.hero-p {
 font-size: 0.9375em;
 color: $darkgray;
 margin-top: 50px;

 @media screen and (max-width: $mobile-max) {
  font-size: 1em;
  margin-top: 0;
}
}

.hero-h1{
 font-size: 2.25em;
 line-height: 1.25em;
 font-weight: bold;
 color: $darkgray;

 span {
   color: $maincolor;
 }
 @media screen and (max-width: $mobile-max) {
  font-size: 1.3em;
}


}

.hero {
  font-weight: bold;
  span {
    color: $maincolor
  }
}

.h2 {
  font-size: 1.115em;
  color: $darkgray;
  font-weight: bold;

  @media screen and (max-width: $mobile-max) {
    font-size: 0.9em;
  }

}

.h3 {
  font-size: 1.625em;
  font-weight: bold;
  color: $darkgray;
  span {
    color: $maincolor
  }
}

.offres-hero {
  width: 80%;
  float: right;
  margin-top: 50px;
}


.text-s {
  font-size:0.9375em;
}
.text-m {
  font-size: 1.2em;
}
.text-l {
  font-size: 2em;
}

.text-xl {
  font-size: 2.5em;
}


.lead-h1 {
  font-size: 3em;
  line-height: 1.2em;
  color: #3c3c3c;

}

.lead-h2 {
  color: #3c3c3c;
  font-size: 1.625em;
  font-weight: bold;
  line-height: 1.5em;
  margin-top: 50px;

  @media screen and (max-width: $mobile-max) {
    font-size: 24px;
    margin-top: 20px;
  }
}

.lead-h3 {
  color: #3c3c3c;
  font-size: 1.3em;
  font-weight: bold;
  line-height: 1.5em;
  margin-top: 50px;

  @media screen and (max-width: $mobile-max) {
    font-size: 24px;
    margin-top: 20px;
  }
}


.offre-h2 {
  color: #3c3c3c;
  font-size: 1.625em;
  font-weight: bold;
  line-height: 1.5em;
  margin-top: 50px;

  @media screen and (max-width: $mobile-max) {
    font-size: 21px;
    margin-top: 20px;
  }
}

.lead-p {
  font-size: 0.9em;

  @media screen and (max-width: $mobile-max) {
    font-size: 14px;
  }
}

.lead-h1 span, .lead-h2 span, .lead-h3 span {
  color: #fda044
}

.lead-error {
  color: red
}

.input-h2 {
  font-family: "fieldwork-geo";
  font-size: 1em;
  color: #3a3a3a;
}