.App {
  position: relative;
  height: 100%;
  // max-width: 1366px;
  margin: auto auto;
  position: relative;

  .container {
    max-width: 1200px;
    margin: auto auto;
    @media screen and (max-width: $mobile-max) {
      padding: 10px;
    }
  }

  .outer-container {
    max-width: 1440px;
    margin: auto auto;
    // padding: 0 20px;
    @media screen and (max-width: $mobile-max) {
      padding: 10px;
    }
  }


  .left-container {
    margin-left: 120px;
  }
}

.NavBar_header {
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $mobile-max) {
    padding-bottom: 20px;
  }
}

.right80 {
  width: 80%;
  @media screen and (max-width: $mobile-max) {
    width: 100%;
  }
}

.callBox {
  display: flex;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  padding: 3px;
  border-radius: 30px;
  align-items: center;
  background: white;
  cursor: pointer;
  max-width: 250px;
  float: right;
  margin-top: 30px;
  border: 2px solid white;
  transition: 0.3s;

  @media screen and (max-width: $mobile-max) {
    margin: 0;
  }
  .icon {
    background-color: $subcolor;
    border: 5px solid $maincolor;
    border-radius: 50%;
    //   padding: 10px;
    height: 40px;
    width: 40px;
  }

  &:hover {
    border: 2px solid $blue;
    .icon {
      background-color: $blue;
      border: 5px solid $blue;
    }
  }

  img {
    margin-top: 7px;
    margin-left: 7px;
    width: 15px;
    height: 15px;
  }

  p {
    margin: 0 10px;
  }

  .callBox_background {
    position: absolute;
    top: 0;
    right: 0;
    background-color:#FEF5E8;
    width: 70px;
    height: 70px;
    z-index: -999;
    border-radius: 0 0 0 30px;

    @media screen and (min-width: $mobile-max) {
      display: none;
    }
  }
}

.callBoxBlue {
  display: flex;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  padding: 3px;
  border-radius: 30px;
  align-items: center;
  background: white;
  cursor: pointer;
  max-width: 250px;
  margin-top: 30px;
  border: 2px solid $blue;
  transition: 0.3s;
  color: $blue;

  .icon {
    background-color: $blue;
    border: 2px solid white;
    border-radius: 50%;
    padding: 4px;
    height: 40px;
    width: 40px;
  }

  &:hover {
    background-color: $blue;
    border: 2px solid $blue;
    color: white;
    .icon {
      background-color: $blue;
      border: 2px solid white;
    }
  }

  img {
    margin-top: 7px;
    margin-left: 7px;
    width: 15px;
    height: 15px;
  }

  p {
    margin: 0 10px;
  }
}

.hero-home {
  width: 100%;

  &:hover {
    cursor: pointer;
  }
}

.hero-mobile {
  margin-top: 30px;
  max-width: 100vw;
  position: absolute;
  right: 0;
}




#background1 {
  position: absolute;
  top: -150px;
  left: -150px;
  width: 391px;
  height: 450px;
  background-color: #fff8f0;
  z-index: -999;

  @media screen and (max-width: $mobile-max) {
    display: none;
    top: -180px;
    width: 100%;
     height: 100px;
  }
}

#background2 {
  position: absolute;
  top: 0px;
  right: -30px;
  width: 500px;
  height: 100vh;
  background-color: #fff8f0;
  z-index: -999
}


#background3 {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 20vw;
  height: 100%;
  background-color: #fff8f0;
  z-index: -999
}

.mapHeight {
  height: 700px !important;

  @media screen and (max-width: $mobile-max) {
    margin-top: 30px;
    height: 400px !important;
  }
}
.expo-hover {
  border: 1px solid #fff;
}
.expo-hover:hover {
  border: 1px solid #f5f3f1;
  background: #fcfcfc;
  border-radius: 50px;

  
  @media screen and (max-width: $mobile-max) {
    border-radius: 40px;
    padding: 10px 0;
  }

}

.selected-expo {
  border: 1px solid #f5f3f1;
  border-radius: 50px;
  text-align: center;
  background-color: #fcfcfc;
  width: 100%;

  @media screen and (max-width: $mobile-max) {
    border-radius: 40px;
    padding: 10px 0;
  }
}

.exposition-list {
  width: 600px;
  margin-top: 20px;
  @media screen and (max-width: $mobile-max) {
    width: 100%;
    margin-top: 0;
  }
  input {
    display: none;
  }

  label {
    cursor: pointer;
    font-weight: bold;
    font-size: 0.9em;
    color: #646464;
    @media screen and (max-width: $mobile-max) {
      font-size: 0.6em;
    }
    img {
      width: 65px;
      @media screen and (max-width: $mobile-max) {
        width: 35px;
      }
    }


    
  }
  div {
    width: 100px;
    display: inline-block;
    margin: 10px 30px 20px 0;
    text-align: center;

    @media screen and (max-width: $mobile-max) {
      max-width: 21vw;
      margin: 0;
    }
  }
}

.compass {
  position: absolute;
  right: -125px;
  top: 20px;
  z-index: 99999999999;
}



.civil-container {
  margin-top: 10px;
  display:flex;
  label {
    margin-right: 30px;
  }
  input {
    margin: 0 5px;
  }
}

.logo-container {
  margin-top: 50px;

  @media screen and (max-width: $mobile-max) {
    margin-top: 15px;
    img {
      width: 80px;
    }
  }
}

.inputError {
  color: red;
  margin:10px;
}
.input-container {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  padding: 5px 0 5px 20px;
  max-width: 100%;
  width: 270px;
  border-radius: 50px;
  margin: 20px 10px 0px 0;
  color: #999;
  font-size: 1.2em;


  @media screen and (max-width: $mobile-max) {
    padding: 5px 20px;
  }



  label {
    color: black;
    font-size: 1em;
  }

  input {
    // max-width: 50px;
    color: #999;
    font-size: 1em;
  }
  input::placeholder {
    color: #d6d6d6;
  }
}


.input-none {
  input[type="radio"] {
    display: none;
  }
  input[type="radio"]:checked + label {
    div {
      border: 3px solid $blue;
    }
  }
}

.slider-container {
  height: 220px;
  max-width: 100%;
  margin-left: 80px;
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  justify-content: center;

  img {
    width: 80%;
  }

  @media screen and (max-width: $mobile-max) {
    height: 150px;
    margin: 80px 0 30px 0;
  }


  datalist {
    display: flex;
    justify-content: space-between;
    color: $darkgray;

    option {
      padding-left: 25px;
    }
  }
}

.slider {
  margin-left: -20px;
  margin-top: -14px;
  input {
    width: 480px;
    max-width: 100%;

    @media screen and (max-width: $mobile-max) {
      width: 280px;
    }
  }
}

// Slider Styling!! //
/* FireFox */
@supports (-moz-appearance: none) {
  .slider-colors {
    outline: none;
  }
  .slider-colors::-moz-range-progress {
    height: 20px;
    background: $maincolor;
  }
  .slider-colors::-moz-range-track {
    height: 20px;
    background: #666;
  } /* high value color */
  .slider-colors::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: red;
  }
}
/* Chrome */
@supports (-webkit-appearance: none) {
  .slider-colors {
    -webkit-appearance: none;
    background: #666;
    height: 10px;
    border-radius: 10px;
    overflow: hidden;
    outline: none;
  } /* high value color */
  .slider-colors::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    margin-top: -10px;
    background: white;
    border-radius: 10px;
    box-shadow: -187px 0 0 180px $maincolor;
    border: 3px solid black;
    z-index: 99999;
  }
}

.basic {
  cursor: pointer;
  margin-left: 20px;
}

.btn-blue {
  background-color: $blue;
  border: 3px solid $blue;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 1em;
  font-weight: bold;
  margin: 20px 0;

  &:hover {
    background-color:white;
    border: 3px solid $blue;
    color: $blue;
  }

  @media screen and (max-width: $mobile-max) {
    padding: 5px 10px;
    width: 100%;
    font-size: 0.9em;
  }

}

.accordion-title {
  display: inline-block;
  font-size: 1.2em;
  margin-bottom: 15px;
  width: 100%;
  padding: 20px 20px 15px 20px;
  background-color: #f9f7f6;
  cursor: pointer;
  text-align:left;

  &.open {
    background-color: #fff;
  }

  .accordion-point {
    margin-right: 7px;
    margin-top: 2px;
    vertical-align:top;
  }

  .accordion-btn {
    float: right;
    width: 30px;
  }

  .accordion-card {
    width: 250px;
    position: relative;
    border-radius: 20px;
    padding: 0px 5px;
    border: 1px solid #ede9e6;
    transition: all 0.3s ease-in-out;
    background-color: $lightmain;
    z-index: 1;
    &:hover {
      // border-top: 6px solid #0080AD;

      transform: scale(1.02);
      z-index: 9999;
    }

    .info {
      float: right;
      width: 25px;
    }

    p {
      font-size: 0.8em;
    }

    h3 {
      font-size: 1em;
    }
  }
}

.accordion-item {
  cursor: text;
}
.btn-main {
  background-color: $subcolor;
  border: 5px solid $maincolor;
  color: white;
  padding: 10px;
  border-radius: 30px;
  font-size: 0.8em;
  font-weight: bold;
  width: 100%;
  transition: 0.5s;

  &:hover {
    background-color: $blue;
    border: 5px solid $blue;
  }
}

.offres-card-mobile {
  margin: 20px 0 70px 0px;

  h1 {
    font-size: 1.3em;

    img {
      vertical-align: top;
    }
  }

  .slick-slide {
    padding: 0 5px;
    text-align:center;
  }

}
.accordion-card-mobile{
  border: 1px solid #ede9e6;
  margin-top: 10px;
  border-radius: 15px;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 1.7em;
    margin-top: 5px;
  }
  h3 {
    font-weight: bold;
    font-size: 1em;
  }

  .card-section {
    padding: 10px 20px;
  }

  .card-section:last-child {
    background-color: #fff5e8;
    border-radius: 0 0 15px 15px;
  }
  .borders {
    border-top:  1px solid #ede9e6;
    border-bottom:  1px solid #ede9e6;
  }

  .card-btn-main {
    background-color: $subcolor;
    border: 5px solid $maincolor;
    color: white;
    padding: 12px 25px;
    border-radius: 30px;
    font-size: 1.3em;
    font-weight: bold;
    transition: 0.5s;
  
    &:hover {
      background-color: $blue;
      border: 5px solid $blue;
    }
  }



}

.offres-year-list {
  margin-left: 10px;
  li {
    display: inline-block;
    margin: 0 20px 0 0;
    border: 1px solid #ede9e6;
    padding: 5px 15px;
    font-size:  0.8em;
    border-radius: 20px;
    color: #878787;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  }

  li#selected {
    border: 1px solid $maincolor;
    color: $maincolor;
  }
}

.offres-year-content {
  .card {
    width: 150px;
    height: 150px;
    border: 1px solid #ede9e6;
    margin: 10px;
    border-radius: 15px;

    div:first-child {
      padding: 15px;
      border-radius: 15px 15px 0 0;
      background-color: #f9f7f6;
      h3 {
        font-size: 0.9em;
        font-weight: bold;
        line-height: 1.2em;
      }
    }

    div:last-child {
      h2 {
        font-weight: bold;
      }
    }
  }
}

.icon-round {
  display: inline-block;
  background-color: $subcolor;
  border-radius: 50%;
  padding: 3px 10px;
  border: 5px solid $maincolor;

  img {
    width: 25px;
    height: 25px;
  }

  @media screen and (max-width: $mobile-max) {
    img {
      width: 14px;
    }
  }



}

.whiteBoxContainer {
  display: flex;
  max-width: 400px;
  border-radius: 40px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  padding: 5px 5px 5px 20px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: $mobile-max) {
    padding: 5px 10px 5px 20px;

    p {
      font-size: 0.8em;
    }
  }



  button {
    background-color: $subcolor;
    border-radius: 50px;
    color: white;
    font-size: 1.3em;
    padding: 5px 5px 5px 20px;
    border: 5px solid $maincolor;
    font-weight: bold;
    transition: 0.3s;

    &:hover {
      background-color: $blue;
      border: 5px solid $blue;
    }
    
    img {
      width: 40px;
      font-size: 1em;
      vertical-align: middle;
      margin-left: 10px;
    }

    @media screen and (max-width: $mobile-max) {
      padding: 5px 5px 5px 15px;
      font-size: 0.9em;

      img {
        width: 25px;
        margin-left: 0px;
      }
    }


  }
}

// .contact-loader {
//   width: 100%;
//   max-height: 100vh;
//   background-color: #fff;
//   opacity: 0.8;
//   position: absolute;
//   z-index: 999;
//   top: 0;
//   display: flex;
//   align-self: center;
//   align-items: center;
//   justify-content: center;
//   left: 0;

//   div {
//     width: 100px;
//     height: 100px;
//     border: 1px solid red;
//   }
// }

.slick-slider{
  margin: 15px 0!important;
  .slick-list {
    .slick-track {
      margin: 0!important;
    }
  }
  @media screen and (max-width: 320px) {
    h3 {
      font-size: 1em;
    }
  }
}

.slider-card-benefits {
  li {
    list-style: none;
    width: 100%;
    padding: 5px 20px;
    border-bottom: 1px solid #ede9e6;

    img {
      display: inline-block;
      vertical-align: top;
      padding-top: 2px;
      margin-right: 5px;
    }
  }
}

#focusCard {
  transform: scale(1);
}

#unfocusCard {
  transform: scale(0.95);
  opacity: 0.4;
}

#metres{
  text-align:center;
  width: 100px;
}

.logo {
  margin: 58px 0px;
  @media screen and (max-width: $mobile-max) {
    margin: 10px 0
  }
}


.leaflet-zoom-animated {
    .leaflet-interactive:hover, .zoneSelected{
        fill: blue;
        opacity: 1;
    }


}

.toiture-container{
  margin: 20px;

  @media screen and (max-width: $mobile-max) {
    margin-top: 80px;
  }

  @media screen and (max-width: 320px) {
    margin-top: 30px;
  }

}
.toiture-img-container {
  position: relative;
  height: 200px;

  @media screen and (max-width: $mobile-max) {
    height: 130px;
  }
  img {
    position: absolute;
    bottom: -5px;
    max-width: 100%;
    width: 350px;
  }
}

.toiture-slider-container {
  width: 350px;
  max-width: 100%;
  margin-bottom: 50px;
}


.facture-montant-slider {
  margin-top: 70px;
  width: 400px;
  max-width: 100%;
  margin-left: -20px;
  display: flex;
  img {
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin: -5px 20px 30px 20px;
  }

}


.btn-restart {
  text-align: right;
  color: $blue;
  margin-top: -20px;
  z-index: 999999999999999999999999999999;
  cursor: pointer;
  text-decoration: underline;
  transition: 0.3s;
  @media screen and (max-width: $mobile-max) {
    margin-top: 10px;
    text-align: left;
  }

  &:hover {
    color: $maincolor;
  }
}


.tooltip-wrapper {
  position: relative;
  z-index: 9999999999;
  height: 20px;
  opacity: 0;
  transition: 0.5s;
}
.tooltip {
  position: absolute;
  right: -3px;
  top: 0x;
  width: fit-content;
  background-color: #fff;
  border: 1px solid #efece9;
  max-width: 220px;
  line-height: 12px;
  font-size: 12px;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}

.tooltip::before {
  position: absolute;
  text-align: center;
  content: "";
  height: 10px;
  width: 10px;
  background-color: #ffffff;
  bottom: -6px;
  border: 1px solid #efece9;
  border-top: 0;
  border-left: 0;
  right: 21px;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}
.top-wrapper {
  margin-bottom: 10px;
}
.top-wrapper:hover {
  .tooltip-wrapper {
    opacity: 1;
  }
}