
.stepBar {
    width: 100%;
    li {
      display: inline-block;
      position:relative;
      width: 16.66%;
      line-height: 42px;
      text-align: center;
      height: 100px;
      align-items: center;
      font-size: 1.8em;
      // display: inline-flex;
      color: white;
      text-shadow: 2px 2px 0px #fdca95;
      font-weight: 500;
      // justify-content: center;
      padding: 30px 10px 20px 10px;
      &#selected {
        color: $maincolor;
        text-shadow: 0px 0px 0px;
  
        span {
          border-bottom: 3px solid $maincolor;
          box-shadow: 0px 0px 0px;
        }
      }

      >.checkedStep {
        position:absolute;
        top: 10px;
        left: 40%;
      }
      
      span {
        padding-bottom: 0px;
        border-bottom: 3px solid white;
        box-shadow: 0px 2px 0px #fdca95;
        -webkit-box-shadow: 0px 2px 0px #fdca95;
        -moz-box-shadow: 0px 2px 0px #fdca95;
             box-shadow: 0px 2px 0px #fdca95;
      }
    }
  
    li:nth-child(1) {
      background-color: #fff9f1;
    }
  
    li:nth-child(2) {
      background-color: #fff1e2;
    }
  
    li:nth-child(3) {
      background-color: #ffebd5;
    }
  
    li:nth-child(4) {
      background-color: #ffe4c6;
    }
  
    li:nth-child(5) {
      background-color: #ffe4c6;
    }
  
    li:nth-child(6) {
      background-color: #ffd8ac;
    }


    &.short {
        li {
            margin-bottom: 10px;
        }
    }
    &.long {
        li {
            height: calc(100vh - 110px);
        }
    }

    &.full {
      li {
          height: 100vh;
      }
  }

  }

