@each $label in $textAlignments {
  .align-#{$label} {
    text-align: $label;
  }
}

@each $value in $marginValues {
  .br-#{$value} {
    border-radius: #{$value}px;
  }
}

.test {border: 1px solid red;}

.w-100 {
  width: 100%;
}

.w-30 {
  width: 30%;
}

.w-50 {
  width: 50%;
}

.w-70 {
  width: 70%;
}

.v-align {
  vertical-align: top;
}

.only-show-mobile {
  @media screen and (min-width: $mobile-max) {
    display: none;
  }
}


.bg-main {
  background-color: $maincolor;
}

.bg-sub {
  background-color: $subcolor;
}

.bg-lightmain {
  background-color: $lightmain;
}

.errorMsg {
  color: red
}

.is-hidden {
  display: none;
}


// Loader white
.lds-ring {
  display: inline-block;
  position: relative;
  width: 25px;
  height: 25px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2.5px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// loader blue


// Loader
.lds-ring-2 {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}
.lds-ring-2 div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  border: 5px solid $maincolor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $maincolor transparent transparent transparent;
}
.lds-ring-2 div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring-2 div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring-2 div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


// ===========
//  Buttons
// ===========


.nextBtn {
  background-color: #f0411d;
  border: 4px solid #f79543;
  color: white;
  border-radius: 40px;
  padding: 20px 20px;
  font-size: 0.9375em;
  font-weight: bold;
  transition: 0.3s;
  text-shadow: 1px 1px 2px rgba(0,0,0, 0.5);

  &:hover {
    background-color: $blue;
    border: 4px solid $blue;
  }

  @media screen and (min-width: $mobile-max) {
    padding: 15px 20px;
  }
}


.btn-delais {
  width: 350px;
  max-width: 100%;


  @media screen and (min-width: $mobile-max) {
    .icon-round img{
      width: 15px;
      height: 15px;
    }
  }


}
.btn-valider {
  display: inline-block;
  background-color: $subcolor;
  font-size: 1.2em;
  margin-right: 20px;
  text-shadow: 1px 1px 2px rgba(0,0,0, 0.5);
  padding: 15px 20px;
  color: #fff;
//  font-weight: bold;
  border-radius: 50px;
  border: 5px solid $maincolor;
  cursor: pointer;
  transition: 0.3s;


  &:hover {
    background-color: $blue;
    border: 5px solid $blue;
  }


  img {
    vertical-align: middle;
    width: 25px;
    margin: 0 3px;
    margin-top: -2px;
  }
}

.btn-navigation {
  display: inline-block;
  cursor: pointer;
  background-color: #faf9f8;
  font-size: 1em;
  color: #b1b1b0;
  font-weight: 500;
  padding: 3px;
  border-radius: 20px;
  border: 1px solid #ede9e6;
  cursor: pointer;
  transition: 0.3s;
  span {
    margin: 3px 5px;
  }
  img {
    vertical-align: middle;
    width: 22px;
    margin: 3px;
  }

  &:hover {
    background-color: #fff;
    color: $maincolor;
  }
}

.btn-loading {
  display: inline-block;
  background-color: #999;
  font-size: 1.2em;
  padding: 15px 20px;
  color: #fff;
  font-weight: 500;
  border-radius: 50px;
  border: 5px solid $maincolor;
  cursor: pointer;
  img {
    vertical-align: middle;
    width: 22px;
    margin: 3px;
  }
}

.btn-normal {
  display: inline-block;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 2px solid white;
  font-size: 1.2em;
  padding: 5px;
  color: #3a3a3a;
  font-weight: 500;
  border-radius: 50px;
  cursor: pointer;
  margin: 10px 0;

  @media screen and (max-width: $mobile-max) {
    font-size: 0.8em;
    padding: 5px;
  }

  span {
    margin: 0 10px;
  }
  img {
    vertical-align: middle;
    width: 40px;

    @media screen and (max-width: $mobile-max) {
      width: 25px;
    }
  }

  .icon-hover2 {
    display:none;
  }


  &:hover {
    border: 2px solid $blue;
    box-shadow: 0;

    .icon-hover1 {
      display: none;
    }
    .icon-hover2 {
      display: inline-block;
    }
    .cls-1 {
      fill: red;
    }
  }
}

.leftCol {
  padding: 0 20px;
  @media screen and (max-width: $mobile-max) {
    padding: 0;
  }
}

.metresBadge {
  float:right;
  background-color: #eeeae7;
  color: #151314;
  border-radius: 50%;
  padding: 13px 20px;
  margin: -28px 7px 0 0 ;


  @media screen and (max-width: $mobile-max) {
    margin: -28px -12px 0 0
  }

}