// ==============
//    Reset
// ==============

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  position: relative;
  font-family: $mainfont;
  color: $darkgray;
}

h1,
h2,
h3 {
  margin: 0;
  font-weight: normal;
}

button {
  border: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;

  &:focus,
  &:active {
    outline: 0;
  }
}

input,
select {
  border: 0;
}

input[type="checkbox"] {
  margin: 0;
}

p,
label {
  font-weight: normal;
}

p {
  margin: 0;
  line-height: 1.2;
}

ul {
  margin: 0;
  padding: 0px 0;
}

a {
  color: black;
  text-decoration: none;
}
