$mainfont: "fieldwork-geo";
$maincolor:#fba03d;
$subcolor:#f33f04;
$lightmain: #fff5e8;
$blue: #316fb2;
$darkgray: #3a3a3a;
$gray:#ecf0f1;
$color-error: #b22222;


$marginValues: (5, 10, 15, 20, 30, 40, 50);
$grid: 12;
$textAlignments : (left, center, right);
$viewport-gap: 0.02px;



// == Breakpoints ==
$tiny: 400px;
$mobile: 576px;
$tablet: 768px;
$desktop: 1200px;
$widescreen: 1800px;

$tiny-max: $mobile - $viewport-gap;
$mobile-min : $mobile+$viewport-gap;
$mobile-max: $tablet - $viewport-gap;
$tablet-min : $tablet+$viewport-gap;
$tablet-max: $desktop - $viewport-gap;
$desktop-max: $widescreen - $viewport-gap;
$breakpoint-map: (tiny: (min-width: null,
max-width: $tiny-max),
mobile: (min-width: null,
max-width: $mobile-max),
tablet: (min-width: $tablet,
max-width: $tablet-max),
desktop: (min-width: $desktop,
max-width: $desktop-max),
widescreen: (min-width: $widescreen,
max-width: null));

// == Grid == 
$grid: 12;